<template>
  <div class="brand w-full pb-10">
    <div
      class="collection-banner-container items-banner-container"
      style="background-color: #251D3D"
    >
      <Banner
        title="All Categories"
        :items="categories.data.length + ' Categories'"
        class="categories-banner"
      >
        <template #logo>
          <Icon class="w-20 h-20" name="all-categories" />
        </template>
        <template #header>
          <ol role="list" class="breadcrumb">
            <li>
              <div class="breadcrumb-item">
                <router-link to="/">Home</router-link>
              </div>
            </li>
            <li>
              <div class="breadcrumb-item">
                <span>/&nbsp;</span><a href="#">Categories</a>
              </div>
            </li>
          </ol>
        </template>
        <template #footer>
          <!-- Footer Slot -->
        </template>
      </Banner>
    </div>

    <!-- Listing -->
    <div class="py-10 space-y-10 max-container">
      <!-- Title & Filters -->
      <div class="flex flex-col lg:flex-row items-center justify-between">
        <h2
          class="text-lg md:text-xl font-semibold text-brand-primary inline-flex items-start mb-4 lg:mb-0"
        >
          <Icon name="fire" class="w-6 h-6 -mt-0.5 mr-0.5" /><span
            >All product categories at Medusa</span
          >
        </h2>

        <!-- Sort Filters -->
        <PopoverGroup
          class="flex sm:items-baseline sm:space-x-8"
          @change="filterCategories()"
        >
          <Popover
            as="div"
            class="relative z-10 inline-block text-left"
            v-slot="{ open }"
          >
            <div
              class="hidden relative md:flex items-center space-x-4 flex-shrink-0"
            >
              <div
                class="flex items-center text-xs font-normal uppercase tracking-wide text-brand-secondary"
              >
                <Icon name="sort" class="w-5 h-5 mr-2" />
                <span>Filter By</span>
              </div>
              <PopoverButton
                class="filter-holder"
                :class="{ 'pointer-events-none': open }"
              >
                <span
                  >{{ selectedbrands.length ? selectedbrands.length : "" }}
                  {{ selectedbrands.length > 1 ? "Brands" : "Brand" }}</span
                >
                <ChevronDownIcon
                  class="flex-shrink-0 -mr-1 ml-1 h-5 w-5 text-brand-body"
                  aria-hidden="true"
                />
              </PopoverButton>
            </div>

            <PopoverButton class="floating-filter-pill">
              <Icon name="sort" class="w-4 h-4 mr-2" />
              <span>Filter Brands</span>
            </PopoverButton>

            <transition
              enter-active-class="transition ease-out duration-100"
              enter-from-class="transform opacity-0 scale-95"
              enter-to-class="transform opacity-100 scale-100"
              leave-active-class="transition ease-in duration-75"
              leave-from-class="transform opacity-100 scale-100"
              leave-to-class="transform opacity-0 scale-95"
            >
              <PopoverPanel class="filter-pill-content">
                <div class="space-y-4 max-h-25 overflow-auto">
                  <div
                    v-for="(option, optionIdx) in brands.data.filter(b => isLoggedIn || b.login_required == '0')"
                    :key="option.id"
                    class="flex items-center"
                  >
                    <input
                      :id="`category-${optionIdx}`"
                      name="categorysort"
                      v-model="selectedbrands"
                      :value="option"
                      type="checkbox"
                      class="h-4 w-4 border-2 border-brand-secondary rounded text-brand-secondary focus:ring-transparent"
                    />
                    <label
                      :for="`category-${optionIdx}`"
                      class="ml-3 pr-6 text-sm font-medium text-brand-secondary whitespace-nowrap"
                    >
                      {{ option.name }}
                    </label>
                  </div>
                </div>
              </PopoverPanel>
            </transition>
          </Popover>
        </PopoverGroup>
      </div>

      <Spinner :loading="categories.loading" />
      <!-- Grids -->
      <div class="categories-grid">
        <router-link
          :to="slugUrl('category/' + cat.slug)"
          class="flex flex-col justify-between bg-white h-64"
          v-for="cat in categories.data.filter(c => isLoggedIn || c.login_required == '0')"
          :key="cat.name"
        >
          <div class="flex items-center justify-center flex-grow p-10">
            <img
              :src="imageBaseUrl(cat.image)"
              :alt="cat.name"
              class="max-w-full h-32 object-contain"
            />
          </div>
          <div class="cat-link">
            <p>{{ cat.name }}</p>
          </div>
        </router-link>
      </div>
    </div>
  </div>
</template>

<script>
import { ref } from "vue";
import {
  Popover,
  PopoverButton,
  PopoverGroup,
  PopoverPanel,
} from "@headlessui/vue";
import { ChevronDownIcon } from "@heroicons/vue/solid";
import Banner from "../common/Banner.vue";
import Categories from "@/modules/Categories";
import Brands from "@/modules/Brands";

export default {
  name: "Categories",
  components: {
    Popover,
    PopoverButton,
    PopoverGroup,
    PopoverPanel,
    ChevronDownIcon,
    Banner,
  },
  setup() {
    const imageBaseUrl = (image) => {
      if (image === null) {
        return "/images/no-image.png";
      }
      const base_url_img = process.env.BASE_URL_IMG || "/";
      return base_url_img + "/" + JSON.parse(image).path;
    };

    function filterCategories() {
      categories.data = [];
      getCategories(selectedbrands);
    }

    const slugUrl = (slug) => {
      const base_url = process.env.BASE_URL || "/";
      return base_url + slug;
    };

    const { isLoggedIn, categories, getCategories } = Categories();
    getCategories();

    const { brands, getbrands } = Brands();
    getbrands();

    const selectedbrands = ref([]);

    return {
      categories,
      selectedbrands,
      brands,
      imageBaseUrl,
      slugUrl,
      filterCategories,
      isLoggedIn,
    };
  },
};
</script>

<style lang="scss" scoped>
.collection-banner-container {
  background-image: url("/images/lines-pattern.png");
}
.categories-grid {
  @apply grid grid-cols-1 sm:grid-cols-2 xl:grid-cols-3 2xl:grid-cols-5 gap-6 items-stretch;
  min-height: 40vh;
  .cat-link {
    @apply w-full border-t border-brand-ash border-opacity-40 py-3.5 px-5;

    p {
      @apply text-base font-normal text-brand-secondary text-opacity-80 leading-none truncate;
      width: 98%;
    }
  }
}

.max-h-25 {
  @include CssScrollbar();
  max-height: 20rem;
}
</style>
